/**
 * トップページ「心と身体にHOTをつくる。」背景のアニメーションをThree.jsを使って実装
 * @related: frontend/src/page/index.html
 */

// Three.jsライブラリをインポート
import * as THREE from 'three';
import bar1_1 from '../../images/top/animation/company-logo-bar-1-1.jpg';
import bar1_2 from '../../images/top/animation/company-logo-bar-1-2.jpg';
import bar1_3 from '../../images/top/animation/company-logo-bar-1-3.jpg';
import bar1_4 from '../../images/top/animation/company-logo-bar-1-4.jpg';
import bar1_5 from '../../images/top/animation/company-logo-bar-1-5.jpg';
import bar2 from '../../images/top/animation/company-logo-bar-2.jpg';
import bar4 from '../../images/top/animation/company-logo-bar-4.jpg';
import { currentPagePath } from '@/script/common.js';

// シーン設定のための定数
const SCENE_SIZE = 200;
const CAMERA_START_Z = 100;

const CAMERA_END_Z =  180; // タブレットサイズでバーが大きくなりすぎるので、180に変更
const FLOAT_SPEED = 0.05;
const ROTATION_SPEED = 0.02;
const ANIMATION_DURATION = 1500;
const SCROLL_TRIGGER_Y = 600;

window.addEventListener('load', () => {
  // コンテナ要素を取得し、シーン、カメラ、レンダラーを設定
  const containerElement = document.getElementById('animationHomeAboutUsContainer');
  const element = document.getElementById('animationHomeAboutUs');

  if(["/", "/en/", "/ja/"].includes(currentPagePath)) {
    const width =  document.body.clientWidth;
    const height = element.getBoundingClientRect().height;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 1, 1000);
    // カメラの初期位置を設定
    camera.position.z = CAMERA_START_Z;
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    let bars = []

    // レンダラーの設定
    renderer.setClearColor(0x000000, 0);
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    element.appendChild(renderer.domElement);

    // バーのジオメトリと色を定義
    const geometry = new THREE.BoxGeometry(5, 20, 0.1);
    const barTextureList = [
      bar1_1, bar1_2, bar1_3, bar1_4, bar1_5,
      bar2, bar2, bar2, bar2,
      bar2, bar2, bar2, bar2,
      bar4, bar4, bar4, bar4
    ];

    const textureLoader = new THREE.TextureLoader();

    // テクスチャを非同期で読み込む関数
    function loadTextureAsync(url) {
      return new Promise((resolve, reject) => {
        textureLoader.load(url, resolve, undefined, reject);
      });
    }

    // バーを作成し、シーンに追加する関数
    async function createBars() {
      const totalBars = barTextureList.length;
      for (let i = 0; i < totalBars; i++) {
        try {
          const texture = await loadTextureAsync(barTextureList[i]);
          texture.encoding = THREE.sRGBEncoding;

          const material = new THREE.MeshBasicMaterial({ map: texture });
          const bar = new THREE.Mesh(geometry, material);

          // 初期位置と動きのプロパティを設定
          bar.position.set(
            Math.random() * SCENE_SIZE - SCENE_SIZE / 2,
            Math.random() * SCENE_SIZE - SCENE_SIZE / 2,
            Math.random() * SCENE_SIZE - SCENE_SIZE / 2
          );
          bar.userData.velocity = new THREE.Vector3(
            (Math.random() - 0.5) * FLOAT_SPEED,
            (Math.random() - 0.5) * FLOAT_SPEED,
            (Math.random() - 0.5) * FLOAT_SPEED
          );
          bar.userData.rotationSpeed = new THREE.Vector3(
            (Math.random() - 0.5) * ROTATION_SPEED,
            (Math.random() - 0.5) * ROTATION_SPEED,
            (Math.random() - 0.5) * ROTATION_SPEED
          );
          scene.add(bar);
          bars.push(bar);
        } catch (error) {
          console.error('テクスチャの読み込みに失敗しました:', error);
        }
      }
      return bars;
    }

    // バーの作成を開始
    createBars().then(bars => {
      // アニメーションループを開始
      animate(bars);
    });

    const screenWidth = document.body.clientWidth;
    const aspectRatio = screenWidth / 375; // 375pxを基準にする
    const x = 25 * aspectRatio
    // アニメーションの目標位置を定義。
    // 「1」の形を作るための各バーの位置
    const targetPositions = [
      // 1段目
      new THREE.Vector3(x, 40, 70),
      new THREE.Vector3(x-12, 40, 70),
      new THREE.Vector3(x-24, 40, 70),
      new THREE.Vector3(x-36, 40, 70),
      new THREE.Vector3(x-48, 40, 70),

      // 2段目
      new THREE.Vector3(x, 12, 70),
      new THREE.Vector3(x-12, 12, 70),
      new THREE.Vector3(x-24, 12, 70),
      new THREE.Vector3(x-36, 12, 70),

      // 3段目
      new THREE.Vector3(x, -16, 70),
      new THREE.Vector3(x-12, -16, 70),
      new THREE.Vector3(x-24, -16, 70),
      new THREE.Vector3(x-36, -16, 70),

      // 4段目
      new THREE.Vector3(x, -44, 70),
      new THREE.Vector3(x-12, -44, 70),
      new THREE.Vector3(x-24, -44, 70),
      new THREE.Vector3(x-36, -44, 70),
    ];

    // アニメーション状態を制御するフラグ
    let isAnimating = false;
    let isFloating = true;
    let isAnimationFinished = false;

    // スクロールイベントリスナーでアニメーションをトリガー
    window.addEventListener('scroll', () => {
      const videoHeight = document.getElementsByClassName("heroVideo")[0].clientHeight

      if (window.scrollY  > videoHeight  && !isAnimating && !isAnimationFinished) {
        isAnimationFinished = true;
        isFloating = false;
        isAnimating = true;
        const startTime = Date.now();

        // 各バーを目標位置にアニメーション
        bars.forEach((bar, index) => {
          const targetPosition = targetPositions[index];
          const startPosition = bar.position.clone();
          const startRotation = bar.rotation.clone();

          function animateBar() {
            const elapsed = Date.now() - startTime;
            if (elapsed < ANIMATION_DURATION) {
              const progress = easeInOutCubic(elapsed / ANIMATION_DURATION);
              bar.position.lerpVectors(startPosition, targetPosition, progress);
              bar.rotation.x = startRotation.x * (1 - progress);
              bar.rotation.y = startRotation.y * (1 - progress);
              bar.rotation.z = startRotation.z * (1 - progress);
              requestAnimationFrame(animateBar);
            } else {
              bar.position.copy(targetPosition);
              bar.rotation.set(0, 0, 0);
            }
          }

          animateBar();
        });

        // カメラの動きをアニメーション
        function animateCamera() {
          const elapsed = Date.now() - startTime;
          if (elapsed < ANIMATION_DURATION) {
            const progress = easeInOutCubic(elapsed / ANIMATION_DURATION);
            camera.position.z = CAMERA_START_Z + (CAMERA_END_Z - CAMERA_START_Z) * progress;
            requestAnimationFrame(animateCamera);
          } else {
            camera.position.z = CAMERA_END_Z;
            isAnimating = false;
          }
        }

        // スムーズなアニメーションのためのイージング関数
        function easeInOutCubic(t) {
          return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
        }

        animateCamera();
      }
    });

    // メインアニメーションループ
    function animate() {
      if (isFloating) {
        // バーの浮遊アニメーション
        bars.forEach(bar => {
          bar.rotation.x += bar.userData.rotationSpeed.x;
          bar.rotation.y += bar.userData.rotationSpeed.y;
          bar.rotation.z += bar.userData.rotationSpeed.z;
        });
      }

      // シーンをレンダリング
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    }

    // ウィンドウリサイズイベントのハンドラー
    function onWindowResize() {
      // コンテナの新しいサイズを取得
      const newWidth =  document.body.clientWidth;
      const newHeight = element.getBoundingClientRect().height;

      // カメラのアスペクト比を更新
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();

      // レンダラーのサイズを更新
      renderer.setSize(newWidth , newHeight);

      // カメラのX軸の位置を更新（例えば、ウィンドウ幅に基づく何らかの計算による）
      // const newXPosition = newWidth > 1000 ?  (newWidth / 100) : (newWidth / 100) + 40; // 新しいX位置を計算する関数
      camera.position.x = newWidth / 100;
    }

    // ウィンドウリサイズイベントリスナーを追加
    window.addEventListener('resize', onWindowResize, false);

    // アニメーションループを開始
    animate();
  }

});
