/**
 * トップページ「インタビュー」背景のアニメーションをGSAPを使って実装
 * @related: frontend/src/page/index.html
 */

import gsap from 'gsap';

const barList = gsap.utils.toArray('.animationHome__interviewBar');

// 高さ(Y軸)を指定する配列。バーの数だけ指定している
const scaleYList = [
    10, 20, 30, 40, 50, 60, 70, 20, 60, 80, 40, 60, 80
]

barList.forEach((bar, index) => {
    gsap.to(bar, {
        scaleY: scaleYList[index] + Math.random() * 3,
        repeat: -1,
        yoyo: true,
        ease: 'steps(3)',
        duration: 0.2 + Math.random() * 0.3,
        delay: Math.random() * 0.2,
    });
});
