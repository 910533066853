import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { currentPagePath } from '@/script/common.js';

(function() {
  if(!(["/about-us/", "/en/about-us/", "/ja/about-us/"].includes(currentPagePath))) return

  gsap.registerPlugin(ScrollTrigger);

  const pcLogoElement =  document.querySelector('#philosophyLogoForPc')

  gsap.to("#fillRect", {
    attr: { d: "M 0 800 h 160 v -800 H 0 z" }, // パスのd属性を変更して高さを0から800にアニメーション
    duration: 0.6,
    ease: "power1.out",
    scrollTrigger: {
      trigger: pcLogoElement, // この要素がビューポートに入るとトリガー
      start: `top 100`,  // 要素の上端がビューポートの下端に達したときに開始
      end: `bottom 300`, // 要素の下端がビューポートの上端に達したときに終了
      // markers: true,     // マーカーを表示させる
    }
  });
})()
