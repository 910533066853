import * as easing from '../utils/easing.esm.mjs';
import { currentPagePath, delay, disableTransition, mediaQuery } from '../common.js';


/**
 * content-block-gallery
 */

if (document.querySelectorAll('.contentBlockGallery').length) {

/*** carousel ***/
/***
 * スライドショー（サムネイル、次へ前へボタン付き）
 * カレントサムネイルはブラウザ内に収まるように自動で横スクロール
 ***/

const carousel = document.getElementById('carousel');
const carouselItems = carousel.querySelectorAll('li');
const thumbnailsContainer = document.querySelector('.contentBlockGallery__controls');
const thumbnails = document.querySelector('.contentBlockGallery__thumbnails');
const thumbnailItems = thumbnails.querySelectorAll('li');
const prevButton = document.querySelector('.contentBlockGallery__arrows > [data-slide="prev"]');
const nextButton = document.querySelector('.contentBlockGallery__arrows > [data-slide="next"]');
const carouselCount = thumbnailItems.length;
let currentSlide = 0;
let thumbnailsContainerWidth = thumbnailsContainer.offsetWidth;
let thumbnailsWidth = thumbnails.offsetWidth;
let thumbnailsOffsetLeft = thumbnails.getBoundingClientRect().left-thumbnailsContainer.offsetLeft;
let currentThumbnailOffsetLeft_leftEdge = thumbnailItems[currentSlide].getBoundingClientRect().left-thumbnailsContainer.offsetLeft;
let currentThumbnailOffsetLeft_rightEdge = thumbnailItems[currentSlide].getBoundingClientRect().right-thumbnailsContainer.offsetLeft;
let scrollPosition = 0;
let isDragging = false;
let startX, currentX;


// リサイズ時にPCかSPかによりサムネイルのサイズを変えるハンドラ
const handleResizeThumbnailsByDevice = () => {
  if (mediaQuery.matches) {
    thumbnails.style.width = (80 + 80) * carouselCount + 'px';
  } else {
    thumbnails.style.width = (48 + 8) * carouselCount + 'px';
  }
};

// リアクション時のカルーセル更新
const updateCarousel = () => {
  carousel.style.transform = 'translate(-'+ carousel.offsetWidth * (currentSlide) / carouselCount   +'px, 0)';
  carousel.setAttribute('data-slide-to', currentSlide);
  thumbnailItems.forEach((thumbnail) => {
    thumbnail.removeAttribute('aria-current');
  });
  thumbnailItems[currentSlide].setAttribute('aria-current', 'true');

  thumbnailsContainerWidth = thumbnailsContainer.offsetWidth;
  thumbnailsOffsetLeft = thumbnails.getBoundingClientRect().left-thumbnailsContainer.offsetLeft;
  currentThumbnailOffsetLeft_leftEdge = thumbnailItems[currentSlide].getBoundingClientRect().left-thumbnailsContainer.offsetLeft;
  currentThumbnailOffsetLeft_rightEdge = thumbnailItems[currentSlide].getBoundingClientRect().right-thumbnailsContainer.offsetLeft;

  if (currentThumbnailOffsetLeft_leftEdge < 0) {
    thumbnails.style.transform = 'translate(' + (thumbnailsOffsetLeft - currentThumbnailOffsetLeft_leftEdge) + 'px, 0)';
  } else if (currentThumbnailOffsetLeft_rightEdge - thumbnailsContainerWidth > 0) {
    thumbnails.style.transform = 'translate(' + (thumbnailsOffsetLeft - (currentThumbnailOffsetLeft_rightEdge - thumbnailsContainerWidth)) + 'px, 0)';
  }

  carouselItems.forEach((item, index) => {
    if (index === currentSlide) {
      item.setAttribute('aria-current', 'true');
    } else {
      item.removeAttribute('aria-current');
    }
  });
};

// サムネイルクリック時の切り替え処理
const createThumbnailClickHandler = (index) => {
  return (event) => {
    event.preventDefault();
    event.stopPropagation();
    currentSlide = index;
    updateCarousel();
  };
};

// 前へボタンクリック時の切り替え処理
const handlePrevButtonClick = (event) => {
  event.preventDefault();
  event.stopPropagation();
  currentSlide = currentSlide === 0 ? carouselCount - 1 : currentSlide - 1;
  updateCarousel();
};

// 次へボタンクリック時の切り替え処理
const handleNextButtonClick = (event) => {
  event.preventDefault();
  event.stopPropagation();
  currentSlide = currentSlide === carouselCount - 1 ? 0 : currentSlide + 1;
  updateCarousel();
};

// サムネイルカルーセルの限界可動域（横）を算出
const calculateThumbnailsScrollAmount = () => {
  thumbnailsContainerWidth = thumbnailsContainer.offsetWidth;
  thumbnailsWidth = thumbnails.offsetWidth;
  const thumbnailsScrollAmount = thumbnailsWidth - thumbnailsContainerWidth;
  return thumbnailsScrollAmount;
};
let thumbnailsScrollAmount = calculateThumbnailsScrollAmount();

// サムネイルカルーセルのスクロール処理
const scrollToThumbnails = (newScrollPosition) => {
  if (0 <= newScrollPosition && newScrollPosition <= thumbnailsScrollAmount) {
    scrollPosition = newScrollPosition; // 次の指定移動先が可動範囲内の時 ==> 次の指定移動先へ
  } else if (thumbnailsScrollAmount < newScrollPosition && scrollPosition != thumbnailsScrollAmount) {
    scrollPosition = thumbnailsScrollAmount; // スクロール位置が最右端ではなく次の指定移動先が最右端以上の時 ==> 最右端へ
  } else if (newScrollPosition < 0 && scrollPosition != 0) {
    scrollPosition = 0; // スクロール位置が最左端ではなく次の指定移動先が最左端以下の時 ==> 最左端へ
  }
  thumbnails.style.transform = `translateX(-${scrollPosition}px)`;
};

// マウスホイール移動時のハンドラ
const handleWheel = (event) => {
  event.preventDefault();
  const delta = Math.sign(event.deltaY);
  const newScrollPosition = scrollPosition + delta * 100;
  scrollToThumbnails(newScrollPosition);
};

// タッチ開始時のハンドラ
const handleTouchStart = (event) => {
  isDragging = true;
  startX = event.touches[0].pageX;
};
// タッチ経過時のハンドラ
const handleTouchMove = (event) => {
  if (isDragging) {
    currentX = event.touches[0].pageX;
    const delta =  Math.sign(currentX - startX);
    const newScrollPosition = scrollPosition - delta * 100;
    scrollToThumbnails(newScrollPosition);
    startX = currentX;
    event.preventDefault();
  }
};
// タッチ終了時のハンドラ
const handleTouchEnd = () => {
  isDragging = false;
};

// 矢印キー押下時のハンドラ
const handleKeyDown = (event) => {
  const delta = (() => {
    switch (event.key) {
      case 'ArrowLeft':
        return -100;
      case 'ArrowRight':
        return 100;
      default:
        return 0;
    }
  })();
  const newScrollPosition = scrollPosition + delta;
  scrollToThumbnails(newScrollPosition);
};

// ドラッグ開始時のハンドラ
const handleDragstart = (event) => {
  isDragging = true;
  startX = event.pageX;
};
// ドラッグ経過時のハンドラ
const handleDragMove = (event) => {
  if (isDragging) {
    currentX = event.pageX;
    const delta = Math.sign(currentX - startX);
    const newScrollPosition = scrollPosition + delta * 100;
    scrollToThumbnails(newScrollPosition);
    startX = currentX;
    event.preventDefault();
  }
};
// ドラッグ終了時のハンドラ
const handleDragend = () => {
  isDragging = false;
};

// リサイズ時のサムネイルカルーセルの可動域（横）を再算出と位置調整
const handleRefreshThumbnailsScroll = () => {
  const newScrollAmount = calculateThumbnailsScrollAmount();
  if (newScrollAmount !== thumbnailsScrollAmount) {
    thumbnailsScrollAmount = newScrollAmount;
    scrollToThumbnails(scrollPosition);
  }
};


const resizeEventTypes = ['resize', 'orientationchange', 'load'];
resizeEventTypes.forEach(eventType => {
  window.addEventListener(eventType, () => {
    handleResizeThumbnailsByDevice();
    handleRefreshThumbnailsScroll();
    updateCarousel();
  });
});
thumbnailItems.forEach((thumbnail, index) => {
  const thumbnailLink = thumbnail.querySelector('a');
  const thumbnailClickHandler = createThumbnailClickHandler(index);
  thumbnailLink.addEventListener('click', thumbnailClickHandler);
});
prevButton.addEventListener('click',handlePrevButtonClick);
nextButton.addEventListener('click',handleNextButtonClick);
thumbnails.addEventListener('wheel', handleWheel);
thumbnails.addEventListener('touchstart', handleTouchStart);
thumbnails.addEventListener('touchmove', handleTouchMove);
thumbnails.addEventListener('touchend', handleTouchEnd);
window.addEventListener('keydown', handleKeyDown);
}// matching currentPagePath
