/**
 * トップページ「お知らせ」背景のアニメーションをGSAPを使って実装
 * @related: frontend/src/page/index.html
 */

import gsap from "gsap";
import {currentPagePath} from "@/script/common";
// バーのセットが並ぶ全体の幅を計算する
const barsSet = document.querySelector('.animationHome__newsBarGroup');
if(["/", "/en/", "/ja/"].includes(currentPagePath)) {
  const barsSetWidth = barsSet.offsetWidth;

  // アニメーションのタイムラインを作成
  const tl = gsap.timeline({
    repeat: -1, // 無限リピート
    defaults: { ease: 'linear' } // 一定速度のアニメーション
  });

  tl.to('.animationHome__newsBarGroup', {
    x: `-${barsSetWidth}px`, // バーセットの幅だけ左に移動する
    duration: 30, // アニメーションの持続時間（秒）
    ease: "none", // 等速のアニメーション
  }).set('.animationHome__newsBarGroup', { x: 0 }); // アニメーション完了時に位置をリセットする

// タイムラインを再生
  tl.play();

}
