import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { currentPagePath } from '@/script/common.js';

(function() {
  if(!(["/about-us/", "/en/about-us/", "/ja/about-us/"].includes(currentPagePath))) return

  gsap.registerPlugin(ScrollTrigger);

  // アニメーションターゲット
  const targetElements = {
    vision: document.getElementById('visionSvg'),
    mission: document.getElementById('missionSvg'),
  };

  // アニメーション素材 (vision)
  const visionSvg = document.getElementById('visionSvg');
  const diffuseRects = [
    document.getElementById('diffuseRect_01'),
    document.getElementById('diffuseRect_02'),
    document.getElementById('diffuseRect_03'),
    document.getElementById('diffuseRect_04')
  ];

  // アニメーション素材のパラメータ (vision)
  const diffuseRectCentroids = {l: 400 / 2, s: 80 / 2};// 矩形の重心
  const diffuseRectsParams = [
    { centroid: [diffuseRectCentroids.l, diffuseRectCentroids.s], direction: [-1, -1] },
    { centroid: [488 - diffuseRectCentroids.s, diffuseRectCentroids.l], direction: [1, -1] },
    { centroid: [488 - diffuseRectCentroids.l, 488 - diffuseRectCentroids.s], direction: [1, 1] },
    { centroid: [diffuseRectCentroids.s, 488 - diffuseRectCentroids.l], direction: [-1, 1] }
  ];

  // アニメーション素材 (mission)
  const stretchRects = [
    document.getElementById('stretchRect_01'),
    document.getElementById('stretchRect_02')
  ];

  // アニメーション素材のパラメータ (mission)
  const stretchRectsParams = [
    { maxHeight: stretchRects[0].getAttribute('height') },
    { maxHeight: stretchRects[1].getAttribute('height') }
  ];
  const stretchRectsMostHeight = Math.max(stretchRectsParams[0].maxHeight, stretchRectsParams[1].maxHeight);

  // アニメーション進捗の測定点
  const progressPoint = [0, 1];
  let visionRotate = {}, visionMove = {};
  let visionFlag = false;
  let stretch = {};
  let missionFlag = false;

  const initVision = () => {
    const headerHei = document.getElementsByTagName('header')[0].clientHeight;
    const visionSvgScaleRate = 488 / visionSvg.clientWidth;// SVGサイズ拡縮での調整用係数
    // バーの新しい高さを計算 (vision)
    for (let i = 0; i < progressPoint.length; i++) {
      visionRotate[i] = -180 * (1 - progressPoint[i]);
      // 回転値
      visionMove[i] = {
        x:  window.innerWidth * visionSvgScaleRate * (1 - progressPoint[i]),
        y:  window.innerHeight * visionSvgScaleRate / 3  * Math.cbrt(1 - progressPoint[i])
      };// 移動値 (Cubic Curve)
    }

    // 素材のアニメーション (vision)
    for (let i = 0; i < diffuseRectsParams.length; i++) {
      const centroid = diffuseRectsParams[i].centroid;
      const direction = diffuseRectsParams[i].direction;

      gsap.set(diffuseRects[i], {
        attr: {
          "transform": `rotate(${visionRotate[0]}, ${centroid[0] + direction[0] * visionMove[0].x}, ${centroid[1] + direction[1] * visionMove[0].y}) translate(${direction[0] * visionMove[0].x}, ${direction[1] * visionMove[0].y})`
        },
      });

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: targetElements.vision,
          start: `top 75%`,
          end: `bottom bottom`,
          onUpdate: self => { if (self.isActive) visionFlag = true;  },
          // markers: true,
        },
      });

      tl.to(diffuseRects[i], {
        duration: 0.6,
        ease: "power1.out",
        attr: {
          "transform": `rotate(${visionRotate[1]}, ${centroid[0] + direction[0] * visionMove[1].x}, ${centroid[1] + direction[1] * visionMove[1].y}) translate(${direction[0] * visionMove[1].x}, ${direction[1] * visionMove[1].y})`
        },
      });
    }
  };
  initVision();

  const initMission = () => {
    const headerHei = document.getElementsByTagName('header')[0].clientHeight;
    // バーの新しい高さを計算 (mission)
    for (let i = 0; i < progressPoint.length; i++) {
      stretch[i] = stretchRectsMostHeight * progressPoint[i];// 伸縮値
    }

    // 素材のアニメーション (mission)
    for (let i = 0; i < stretchRectsParams.length; i++) {
      const maxHeight = stretchRectsParams[i].maxHeight;
      const limitedHeight = [Math.min(maxHeight, stretch[0]), Math.min(maxHeight, stretch[1])];

      gsap.set(stretchRects[i], {
        attr: {
          "height": `${limitedHeight[0]}`
        },
      });

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: targetElements.mission,
          start: `top 75%`,
          end: `bottom bottom`,
          onUpdate: self => { if (self.isActive) missionFlag = true; },
          // markers: true,
        },
      });

      tl.to(stretchRects[i], {
        duration: 0.6,
        ease: "power1.out",
        attr: {
          "height": `${limitedHeight[1]}`
        },
      });
    }
  };
  initMission();

  // Resize
  const body = document.body;
  let previousWidth = window.innerWidth,
      resizeendTimer = null,
      isResizing = false;

  const resizeHandler = () => {
    const currentWidth = window.innerWidth;
    if(previousWidth !== currentWidth) {
      if(!isResizing) {
        body.classList.add('resizing');
        isResizing = true;
      }
      previousWidth = currentWidth;
      clearTimeout(resizeendTimer);
      resizeendTimer = setTimeout(resizeEndHandler, 100);
    }
  };
  const resizeEndHandler = () => {
    isResizing = false;
    body.classList.remove('resizing');

    if (!visionFlag) initVision();
    if (!missionFlag) initMission();
  };

  window.addEventListener('resize', resizeHandler);
})()
