/**
 * トップページ「リクルート」背景のアニメーションをGSAPを使って実装
 * @related: frontend/src/page/index.html
 */

import gsap from 'gsap';

// インジケーターのバーを選択
const bars = gsap.utils.toArray('.animationHome__recruitBar');
const numberOfBars = bars.length;
const width = window.innerWidth
const radius = width >= 767 ? 400 : 250; // 円の半径
const animationSpeed = 0.05; // アニメーションのスピード

bars.forEach((bar, index) => {
    const startAngle = (360 / numberOfBars) * index;
    const startAngleRad = startAngle * Math.PI / 180; // 初期角度をラジアンに変換

    // 円周上にバーを初期配置
    gsap.set(bar, {
        rotation: startAngle + 90, // 横向きにするために90度追加
        transformOrigin: '50% 50%',
        x: radius * Math.cos(startAngleRad) - bar.offsetWidth / 2,
        y: radius * Math.sin(startAngleRad) - bar.offsetHeight / 2
    });

    // GSAPのタイムラインを作成
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });

    for (let i = 0; i < 360; i++) {
        const currentAngle = (startAngle + i) % 360;
        const currentAngleRad = currentAngle * Math.PI / 180;

        tl.to(bar, {
            rotation: startAngle + 90 + i,
            x: radius * Math.cos(currentAngleRad) - bar.offsetWidth / 2,
            y: radius * Math.sin(currentAngleRad) - bar.offsetHeight / 2,
            duration: animationSpeed,
            ease: "none"
        }, i * animationSpeed);
    }
});
