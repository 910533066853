
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { currentPagePath } from '@/script/common.js';

(function() {
  if(!(["/about-us/", "/en/about-us/", "/ja/about-us/"].includes(currentPagePath))) return

  gsap.registerPlugin(ScrollTrigger);

  // AboutUsページに存在する各セクションのロゴを取得する
  const logoContainerElementList = document.querySelectorAll('.-philosophy, .-vision, .-mission');

  // ロゴを監視
  logoContainerElementList.forEach(logoElement => {
    gsap.to(logoElement, {
      scrollTrigger: {
        trigger: logoElement, // この要素がビューポートに入るとトリガー
        start: `top 10%`,  // 要素の上端がビューポートの上端10%に達したときに開始
        end: `1000% 50%`, // 要素の下端から+900%の位置がビューポートの中央に達したときに終了（ビュー内で発火させない）
        toggleClass: {targets: logoElement.children[0], className: "aboutBrandIdentity__heading__scrollIn"},
        // markers: true, // マーカーを表示させる
      }
    });
  });
})()
