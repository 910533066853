import * as easing from '../utils/easing.esm.mjs';
import { currentPagePath, delay, disableTransition, mediaQuery } from '../common.js';


/**
 * works-all
 */

if (currentPagePath.includes('/works/')) {

  /*** more-Btn ***/
/***
 * モバイルでカテゴリタグエリアをもっと見るでの開閉式にする
 ***/

const categoryMoreTrigger = document.querySelector('[aria-controls="categoryTagsBox"]');
const categoryMoreTarget = document.getElementById('categoryTagsBox');
const categoryMoreToggleTarget = document.querySelectorAll('.categoryTags')[0];

// もっと見るを開閉するハンドラ
const handleMoreBtn = () => {
  const isHidden = categoryMoreTarget.getAttribute('aria-hidden') === 'true';
  categoryMoreTarget.setAttribute('aria-hidden', !isHidden);
  categoryMoreTrigger.setAttribute('aria-expanded', !!isHidden);
};

// タグ3行以下のときにもっと見るを非表示するハンドラ
const handleResizeToggleMoreBtn = () => {
  const categoryTagListHeight = categoryMoreTarget.children[0].offsetHeight;
  const categoryTagHeight = categoryMoreTarget.children[0].children[0].offsetHeight + categoryMoreTarget.children[0].children[0].offsetTop;

  const isHidden = (categoryTagListHeight > categoryTagHeight * 3) === true;
  categoryMoreToggleTarget.setAttribute('aria-hidden', isHidden);
};

const resizeEventTypes = ['resize', 'orientationchange', 'load'];
resizeEventTypes.forEach(eventType => {
  window.addEventListener(eventType, handleResizeToggleMoreBtn);
});
categoryMoreTrigger.addEventListener('click', handleMoreBtn);

/*** page-loading ***/
/***
 * プロジェクト事例の一覧から詳細へ移動時のみ遷移演出を加える
 ***/

const canvasContainer = document.getElementById('pageCanvas');
const canvas = canvasContainer.getElementsByTagName('canvas')[0];
const ctx = canvas.getContext('2d');
const ctxColor = 'rgba(235, 235, 235, 0.95)';
const rectangleSize = { long: 200, short: 48 };
let worksDetailLinks = document.querySelectorAll('.worksCard > li a');
let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
let shapeAngle = localStorage.getItem('shapeAngle') || (Math.PI * 2) / 8;
let animationStartTime;
let targetScale = 50; // 目標の拡大倍率
const animationDuration = 300 * 2; // アニメーションの時間（ミリ秒）
const easingInFunction = easing.easeInQuad; // イージング関数（始点）
const easingOutFunction = easing.easeOutQuad; // イージング関数（終点）

// HTML canvasのリフレッシュ設定（一覧ロード時）
const refreshCanvas = () => {
  canvasContainer.setAttribute('aria-hidden', true);
};



// 矩形の角度をロード毎にランダムにする（全16段階）
const randomAngle = () => {
  const angleDivisionCount = 16;
  const angleStep = (Math.PI * 2) / angleDivisionCount;
  const randomIndex = Math.floor(Math.random() * angleDivisionCount);
  shapeAngle = (angleStep + 2/3) * randomIndex;// +30度ずらし調整（16当分の2/3 = 30）
  localStorage.setItem('shapeAngle', shapeAngle);
  return shapeAngle;
};

// 矩形の拡大率の再計算（ウィンドウがすべて矩形の中に収まるまで）
const initializeScale = () => {
  const normalizeAngle = (shapeAngle % Math.PI > Math.PI / 2) ? Math.PI - shapeAngle % Math.PI: shapeAngle % Math.PI;
  const rotatedWindowWidth = windowWidth * Math.cos(normalizeAngle) + windowHeight * Math.sin(normalizeAngle);
  const rotatedWindowHeight = windowWidth * Math.sin(normalizeAngle) + windowHeight * Math.cos(normalizeAngle);
  const scaleX = rotatedWindowWidth / rectangleSize.short;
  const scaleY = rotatedWindowHeight / rectangleSize.long;
  targetScale = Math.ceil(Math.max(scaleX, scaleY));
}

// HTML canvasの初期設定（一覧クリック時）
const initializeCanvas = () => {
  animationStartTime = Date.now();
  canvasContainer.setAttribute('aria-hidden', true);
  initializeScale();
}

// リサイズ時のHTML canvasサイズの再計算
const resizeCanvas = () => {
  windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  canvas.width = windowWidth;
  canvas.height = windowHeight;
};

// HTML canvasフレーム毎の描画処理
const drawCanvasFrame = (scale) => {
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = ctxColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // マドの描画
  ctx.globalCompositeOperation = "destination-out";
  ctx.beginPath();
  const rectangleWidth = rectangleSize.short * scale;
  const rectangleHeight = rectangleSize.long * scale;
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(shapeAngle);
  ctx.translate(-1 * canvas.width / 2, -1 * canvas.height / 2);
  const rectangleX = (canvas.width - rectangleWidth) / 2;
  const rectangleY = (canvas.height - rectangleHeight) / 2;
  ctx.fillStyle = '#000';
  ctx.fillRect(rectangleX, rectangleY, rectangleWidth, rectangleHeight);
  ctx.fill();
}

// HTML canvasの経過処理
const trackAnimationProgress = (reverse = false) => {
  const currentTime = Date.now();
  const elapsedTime = currentTime - animationStartTime;
  const animationProgress = Math.min(elapsedTime / animationDuration, 1);
  const scale = (reverse) ? targetScale * easingInFunction(animationProgress): targetScale - (targetScale - 1) * easingOutFunction(animationProgress);
  return { elapsedTime: elapsedTime, scale: scale };
}

// HTML canvasのリアルタイムレンダリングを行う処理（汎用）
const renderCanvas = (reverse = false) => {
  resizeCanvas();
  const progressValue = trackAnimationProgress(reverse);
  drawCanvasFrame(progressValue.scale);

  if (progressValue.elapsedTime < animationDuration) {
    requestAnimationFrame(() => {
      renderCanvas(reverse);
    });
  }
};

// 開始ページ（一覧）でのHTML canvasのリアルタイムレンダリングを行う処理
const startRenderCanvas = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const worksCardDetailHref = event.currentTarget.getAttribute('href');
  canvasContainer.setAttribute('aria-hidden', false);
  window.addEventListener('resize', startRenderCanvas);
  requestAnimationFrame(() => {
    renderCanvas(false);
    delay(animationDuration).then(() => {
      window.location.href = worksCardDetailHref;
    });
  });
};

// 到達ページ（詳細）でのHTML canvasのリアルタイムレンダリングを行う処理
const finishRenderCanvas = () => {
  canvasContainer.setAttribute('aria-hidden', false);
  requestAnimationFrame(() => {
    renderCanvas(true);
    delay(animationDuration).then(() => {
      canvasContainer.setAttribute('aria-hidden', true);
      if (localStorage.getItem('shapeAngle')) localStorage.removeItem('shapeAngle');
    });
  });
};

// NOTE: ブラウザバックした時に、キャッシュが残ってしまいcanvasの描画後で表示された後にキャッシュが表示されてしまう。そのために以下の処理を追加
  const clearCanvasForBackForwardCache = (event) => {
    // ブラウザバックした時のキャッシュを読み込んだ時の挙動を想定
    if (event.persisted) {
      const canvasContainer = document.getElementById('pageCanvas');
      if(!canvasContainer) return

      canvasContainer.setAttribute('aria-hidden', true);
    }
  }

// クリックイベントのバンドル（フィルタリング時の再描画用）
const addClickEventListenersForWorksIndex = () => {
  worksDetailLinks.forEach(t => { t.addEventListener('click', randomAngle); });
  worksDetailLinks.forEach(t => { t.addEventListener('click', initializeCanvas); });
  worksDetailLinks.forEach(t => { t.addEventListener('click', startRenderCanvas); });
};

if (currentPagePath.match('\\/works\\/$')) {
  window.addEventListener('DOMContentLoaded', refreshCanvas);
  window.addEventListener("pageshow", clearCanvasForBackForwardCache);
  addClickEventListenersForWorksIndex();
} else {
  if (localStorage.getItem('shapeAngle')) {
    window.addEventListener('DOMContentLoaded', initializeCanvas);
    window.addEventListener('DOMContentLoaded', finishRenderCanvas);
    window.addEventListener('resize', finishRenderCanvas);
  }
}

/**
 * works-index-only
 */

if (currentPagePath.match('\\/works\\/$')) {

/*** lazy-load ***/
/***
 * スクロール位置によって遅延表示の演出を加える
 ***/

const categoryClearTrigger = document.querySelectorAll('[aria-controls="categoryTags"]');
const categoryClearTarget = document.getElementById('categoryTags');
const categoryFilteringTrigger = categoryClearTarget.querySelectorAll(':scope > li .tagBtn a');
const worksListData = window.worksListJson.works;
let worksList = document.getElementById('filteredItems');
let worksListItems = worksList.querySelectorAll('li');
const worksListParent = worksList.parentNode;
let worksListfilteredData = worksListData;
let categoryTagsState = {};


// 遅延表示の演出処理
const revealAnimations = (target) => {
  target.setAttribute('aria-hidden', false);
};

// 遅延表示の処理（アニメーションなし）
const revealWithoutAnimation = (target) => {
  target.classList.add('-skip-animation');
  target.setAttribute('aria-hidden', false);
};

// コールバック ( for Intersection Observer )
const handleIntersection = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      revealAnimations(entry.target);
      observer.unobserve(entry.target);
    }
  });
};

// オプション設定 ( for Intersection Observer )
const options = {
  root: null, // ルート要素（ビューポート）
  rootMargin: '0px', // ルート要素とのマージン
  threshold: 0.5, // 交差判定の閾値 // 50%以上表示されたらコールバックを実行
};

// インスタンスを作成 ( for Intersection Observer )
const observer = new IntersectionObserver(handleIntersection, options);

const stertObservePanels = () => {
  // 遅延読み込み対象のパネルを取得して「Intersection Observer」に監視させる
  worksListItems.forEach(panel => {
    observer.observe(panel);
  });

  // 読み込み時に初回の表示判定を実行
  worksListItems.forEach((panel) => {
    const rect = panel.getBoundingClientRect();
    if (rect.top < window.innerHeight) {
      revealAnimations(panel);
    }
  });
  delay(100 + 1000).then(() => { worksList.classList.remove('-inactive'); });
};
stertObservePanels();

const stertObservePanelsWithoutAnimation = () => {
  // 遅延読み込み対象のパネルを取得して「Intersection Observer」に監視させる
  worksListItems.forEach(panel => {
    observer.observe(panel);
  });

  // 読み込み時に初回の表示判定を実行
  worksListItems.forEach((panel) => {
    const rect = panel.getBoundingClientRect();
    if (rect.top < window.innerHeight) {
      observer.unobserve(panel);
      revealWithoutAnimation(panel);
    }
  });
};

/*** filtering-tags ***/
/***
 * カテゴリタグによるコンテンツの絞り込み
 * 複数選択はORでの処理（部分一致）
 * すべて選択解除時は例外的に全件選択と同じ
 ***/

// カテゴリタグの選択に合わせてDOMデータオブジェクトを更新
const updateCategoryTagsState = (item) => {
  const key = item.dataset.worksCategorytag;
  const value = item.getAttribute('aria-pressed') === 'true';
  categoryTagsState[key] = value;
  const categoryUniqueKeys = [...new Set(Object.keys(categoryTagsState))];
  const categorySortedKeys = categoryUniqueKeys.sort().reverse();
  const sortedCategoryTagsState = categorySortedKeys.reduce((acc, key) => {
    acc[key] = categoryTagsState[key];
    return acc;
  }, {});
  categoryTagsState = sortedCategoryTagsState;
};

// カテゴリタグの選択に合わせてコンテンツの表示を更新
const renderWorksList = () => {
  if(!worksListParent.childNodes.length) return false;

  const worksListContainer = document.createElement('ul');
  worksListContainer.classList.add('worksCard', '-animation', '-inactive');
  worksListContainer.id = 'filteredItems';
  worksListContainer.setAttribute('role', 'list');
  worksListContainer.setAttribute('aria-live', 'polite');
  worksListContainer.setAttribute('aria-hidden', 'true');
  worksListContainer.setAttribute('aria-disabled', 'true');

  worksListfilteredData.forEach((item, index) => {
    const listItem = document.createElement('li');
    listItem.dataset.worksCategory = JSON.stringify(item.tags);
    listItem.setAttribute('aria-hidden', 'flase');
    listItem.innerHTML = `
      <a href="${item.url}">
        <div class="worksCard__header">
          <div class="worksCard__headerInner">
            <div class="worksCard__image">
              <picture>
                ${[0,1,2].includes(index) ?
                  `<source media="(min-width: 768px)" srcset=${item.imageL}>`
                  :`<source media="false" srcset="${item.imageL}">`
                }
                <img loading="lazy" src="${item.image}" alt="">
              </picture>
            </div>
          </div>
        </div>
        <div class="worksCard__body">
          <div class="worksCard__bodyInner">
            <div class="worksCard__context">
              <p class="worksCard__name">${item.name}</p>
              <p class="worksCard__title">${item.title}</p>
            </div>
          </div>
          <div class="worksCard__footer">
            <div class="worksCard__projectTags">
              <ul class="projectTags">
                ${item.tags.map(tag => `<li>${tag}</li>`).join('')}
              </ul>
            </div>
          </div>
        </div>
      </a>
    `;
    worksListContainer.appendChild(listItem);
  });

  worksListParent.innerHTML = '';
  worksListParent.appendChild(worksListContainer);
  worksList = worksListContainer;
  worksListItems = worksListContainer.querySelectorAll(':scope > li');
  worksDetailLinks = worksList.querySelectorAll(':scope > li a');

  addClickEventListenersForWorksIndex();
  stertObservePanelsWithoutAnimation();
  delay(100).then(() => { worksList.setAttribute('aria-hidden', false); }); // 描画後のアニメーション開始（念のため1msの待機）
  delay(100 + 1000).then(() => { worksList.setAttribute('aria-disabled', false);worksList.classList.remove('-inactive'); }); // アニメーション終了までクリックできなくする
};

// カテゴリタグの選択に合わせてフィルタリングして一覧を表示する
const worksFiltering = () => {
  worksListfilteredData = worksListData.filter(item => {
    return Object.values(categoryTagsState).some(value => value === true) ? item.tags.some(tag => categoryTagsState[tag]) : true;
  });
  renderWorksList(worksListfilteredData);
};

// カテゴリタグの選択に合わせてフィルタリングするハンドラ
const handleFilteringCategoryTagContents = (event) => {
  event.preventDefault();
  event.stopPropagation();
  // タグのUI変換
  const isPressed = event.currentTarget.getAttribute('aria-pressed') === 'true';
  event.currentTarget.setAttribute('aria-pressed', !isPressed);

  // 変数 categoryTagsStateの更新
  updateCategoryTagsState(event.currentTarget);
  worksFiltering(event);
};

// カテゴリタグの全件選択解除に合わせてフィルタリングをリセットするハンドラ
const handleClearCategoryTagContents = (event) => {
  event.preventDefault();
  event.stopPropagation();
  let clearCount = 0;
  categoryFilteringTrigger.forEach((item) => {
    if (item.getAttribute('aria-pressed') === 'true') item.setAttribute('aria-pressed', false), clearCount++;
    updateCategoryTagsState(item);
  });
  if(clearCount) worksFiltering(event);
};

categoryFilteringTrigger.forEach((item) => {
  updateCategoryTagsState(item);

  item.addEventListener('click', handleFilteringCategoryTagContents);
});
categoryClearTrigger.forEach(element => element.addEventListener('click', handleClearCategoryTagContents));

}// matching currentPagePath

}// matching currentPagePath
