import * as easing from '../utils/easing.esm.mjs';
import { currentPagePath, delay, disableTransition, mediaQuery } from '../common.js';


/**
 * recruitment
 */

if (currentPagePath.match('\\/recruitment\\/$')) {

/*** change-tab ***/
/***
 * タブによる表示エリアを切り替える
 ***/

const changeTabTrigger = document.querySelectorAll('[aria-controls^="recruitmentGuidelinesTabs-"]');
const changeTabTarget = document.querySelectorAll('[id^="recruitmentGuidelinesTabs-"]');

// タブによる表示エリアを切り替えるハンドラ
const handleChangeTab = (changeTarget) => {
  return (event) => {
    event.preventDefault();
    event.stopPropagation();
    changeTabTrigger.forEach(c => { c.setAttribute('aria-expanded', false); c.removeAttribute('aria-current'); });
    changeTabTarget.forEach(t => { t.setAttribute('aria-hidden', true); t.removeAttribute('aria-current'); });
    event.currentTarget.setAttribute('aria-expanded', true);
    event.currentTarget.setAttribute('aria-current', true);
    changeTarget.setAttribute('aria-hidden', false);
    changeTarget.setAttribute('aria-current', true);
  };
};

changeTabTrigger.forEach(controller => {
  const targetId = controller.getAttribute('aria-controls');
  const changeTarget = document.getElementById(targetId);
  const handleTabClick = handleChangeTab(changeTarget);

  controller.addEventListener('click', handleTabClick);
});

/*** accordions ***/
/***
 * アコーディオンによる開閉を行う
 ***/

const accordionTrigger = document.querySelectorAll('[aria-controls^="recruitmentGuidelinesAccordion-"]');

// アコーディオンによる開閉を行うハンドラ
const handleOpenAccordions = (openTarget, openTrigger) => {
  return (event) => {
    event.preventDefault();
    event.stopPropagation();
    const isExpanded = event.currentTarget.getAttribute('aria-expanded') === 'true';

    openTrigger.forEach(c => c.setAttribute('aria-expanded', !isExpanded));
    openTarget.setAttribute('aria-hidden', isExpanded);

    if (isExpanded) {
      openTarget.style.maxHeight = '0';
    } else {
      openTarget.style.maxHeight = `${openTarget.scrollHeight}px`;
    }
  };
};

// リサイズ時にアコーディオンが開いている場合に高さの再調整をするハンドラ
const handleAccordionChangeHeight = () => {
  accordionTrigger.forEach(controller => {
    const targetId = controller.getAttribute('aria-controls');
    const target = document.getElementById(targetId);

    const isExpanded = controller.getAttribute('aria-expanded') === 'true';
    if (isExpanded) target.style.maxHeight = `${target.scrollHeight}px`;
  });
};


const resizeEventTypes = ['resize', 'orientationchange', 'load'];
resizeEventTypes.forEach(eventType => {
  window.addEventListener(eventType, handleAccordionChangeHeight);
});
accordionTrigger.forEach(controller => {
  const openTargetId = controller.getAttribute('aria-controls');
  const openTarget = document.getElementById(openTargetId);
  const openTrigger = document.querySelectorAll('[aria-controls="'+ openTargetId +'"]')
  const handleAccordionClick = handleOpenAccordions(openTarget, openTrigger);

  controller.addEventListener('click', handleAccordionClick);
});

}// matching currentPagePath
