import * as easing from '../utils/easing.esm.mjs';
import { currentPagePath, delay, disableTransition, mediaQuery } from '../common.js';

/**
 * interviews-index-only
 */

if (currentPagePath.match('^\\/interviews\\/$')) {

/*** more-Btn ***/
/***
 * もっと見るボタンの設置（押下前の表示件数10件）
 ***/

const categoryMoreTrigger = document.querySelector('[aria-controls="interviewListBox"]');
const categoryMoreTarget = document.getElementById('interviewListBox');

// もっと見るを開閉するハンドラ
const handleMoreBtn = (event) => {
  const isHidden = categoryMoreTarget.getAttribute('aria-hidden') === 'true';
  categoryMoreTarget.setAttribute('aria-hidden', !isHidden);
  categoryMoreTrigger.setAttribute('aria-expanded', !!isHidden);

  // トリガーを即削除
  event.target.closest('[role="group"]').remove();
};
categoryMoreTrigger.addEventListener('click', handleMoreBtn, { once: true });

}// matching currentPagePath
