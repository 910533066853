/**
 * トップページ「メンバー」背景のアニメーションをGSAPを使って実装
 * @related: frontend/src/page/index.html
 */

import gsap from 'gsap';

// アニメーションを適用する関数
function applyMovingAnimation() {
    // 各バーにアニメーションを適用
    gsap.utils.toArray('.animationHome__memberBar').forEach((bar, index) => {
        gsap.to(bar, {
            y: 300,
            yoyo: true, // アニメーションを逆方向にも適用
            repeat: -1, // 無限に繰り返す
            ease: "sine.inOut", // イージング関数を設定
            duration: 0.5 + (index * 0.01), // アニメーションの持続時間
        });
    });
}

// アニメーションを適用
applyMovingAnimation();
