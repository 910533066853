/**
 * トップページ「メンバー」背景のアニメーションをGSAPを使って実装
 * @related: frontend/src/page/index.html
 */

// @ref: https://github.com/inos3910/video-embed/blob/master/assets/js/youtube/entry.js
import {currentPagePath} from "@/script/common";

/**
 * YouTubeの動画を再生するクラス。
 * TOPページのヒーローエリアは、YouTubeにアップロードした動画を再生しているため、IFramePlayer APIを使用して動画を再生している。
 * @docs: https://developers.google.com/youtube/iframe_api_reference?hl=ja
 * @related: frontend/src/page/_include/components/hero.html
 */
class Main {
  ytPlayer = null;
  played = false;
  pcElement = null;
  spElement = null;
  ytPlayerOptions = {
    start          : 0, // 動画開始位置
    rel            : 0, // 関連動画の非表示
    controls       : 0, // プレイヤーコントロールの非表示
    disablekb      : 1, // キーボード操作をオフ
    showinfo       : 0, // タイトルなどの非表示
    modestbranding : 1, // YouTubeロゴの非表示
    iv_load_policy : 3, // アノテーションの非表示
    fs             : 0, //全画面表示ボタンの非表示
    autoplay       : 1, //自動再生
    playsinline    : 1, //インライン再生
    loop           : 0, //ループ
  }
  playTimerInInitialize = null; // 初期ロード時、イベントが発生しなかった場合のタイマー処理
  playedSpOnce = false; // 動画が一度でも再生されたかどうか
  TIME_OUT = 2500;  // 動画が再生できない場合のタイムアウト処理の時間


  constructor() {
    this.pcElement = document.getElementById("youtubeForPc")
    this.spElement = document.getElementById("youtubeForSp")

    this.addYoutubeScript();
    this.onYouTubeIframeAPIReady();
  }

  /**
   * YouTube IFrame Player APIを読み込み
   */
  addYoutubeScript() {
    const tag = document.createElement('script');
    tag.src = "//www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  /**
   * 動画の再生
   */
  onYouTubeIframeAPIReady() {
    window.onload = () => {
      // NOTE: PCの場合
      this.ytPlayer = new YT.Player(this.pcElement, {
        videoId: this.pcElement.getAttribute('data-youtube-id'),
        playerVars: this.ytPlayerOptions,
        events: {
          onReady: this._onPlayerReady.bind(this),
          onStateChange: this._onPlayerStateChangeForPc.bind(this),
        }
      });

      // NOTE: SPの場合
      // タイマーを設定し、3秒後に_checkEventResponseを呼び出す
      this.playTimerInInitialize = setTimeout(() => this._handleNoResponse(), this.TIME_OUT);

      this.ytPlayer = new YT.Player(this.spElement, {
        videoId: this.spElement.getAttribute('data-youtube-id'),
        playerVars: this.ytPlayerOptions,
        events: {
          onReady: this._onPlayerReady.bind(this),
          onStateChange: this._onPlayerStateChangeForSp.bind(this),
        }
      });

    };
  }

  /**
   * 動画の準備完了後の動作
   * @private
   */
  _onPlayerReady(e) {
    e.target.setPlaybackQuality('highres');
    e.target.mute();
    e.target.playVideo();
  }

  /**
   * PC用の動画再生中の操作
   * @private
   */
  _onPlayerStateChangeForPc(e) {
    const ytStatus = e.target.getPlayerState();

    if (ytStatus === YT.PlayerState.PLAYING) {
      const playerElement = document.getElementById("youtubeForPc");
      playerElement.parentElement.classList.add('isLoaded');

      // LEDスクリーンテクスチャーの表示
      const overlayElement = document.getElementById("youTubeIframeOverlayTexture");
      overlayElement.classList.add('isLoaded');

      if (!this.played) {
        this.played = true;
        const duration = this.ytPlayer.getDuration();
        const timer = setInterval(() => {
          this.ytPlayer.seekTo(0);
          this.ytPlayer.playVideo();
        }, parseInt(duration * 1000));
      }
    }
    // 再生後の処理
    if (ytStatus === YT.PlayerState.ENDED) {
      const playerElement = document.getElementById("youtubeForPc");
      playerElement.parentElement.classList.remove('isLoaded');
      e.target.seekTo(0);
    }
  }


  /**
   * SP用の動画再生中の操作
   * @private
   */
  _onPlayerStateChangeForSp(e) {
    // タイマーをクリアして、_handleNoResponseが呼ばれないようにする
    clearTimeout(this.playTimerInInitialize);
    const ytStatus = e.target.getPlayerState();

    // 動画が終了した場合
    if (ytStatus === YT.PlayerState.ENDED) {
      const playerElement = document.getElementById("youtubeForSp");
      playerElement.parentElement.classList.remove('isLoaded');
      e.target.seekTo(0);
      return;
    }

    // 動画が再生状態になり、まだ一度も再生されていない場合
    const playerElement = document.getElementById("youtubeForSp");
    playerElement.parentElement.classList.add('isLoaded');

    // LEDスクリーンテクスチャーの表示
    const overlayElement = document.getElementById("youTubeIframeOverlayTexture");
    overlayElement.classList.add('isLoaded');

    if (!this.playedSpOnce) {
      this.playedSpOnce = true; // 再生されたことをマーク

      // ループ再生のような挙動を設定
      const duration = this.ytPlayer.getDuration();
      const timer = setInterval(() => {
        this.ytPlayer.seekTo(0);
        this.ytPlayer.playVideo();
      }, parseInt(duration * 1000));
    }
  }

  /**
   * 特定の時間内にイベントが発生しなかった場合の処理
   * これはiPhoneの低電力モードで動画が再生できないことがあったので、
   */
  _handleNoResponse() {
    // 画像のimgタグを作成し、表示する
    const wrapperElement = document.getElementsByClassName("heroVideo__content -sp")[0];
    const imgElement = document.createElement('img');
    imgElement.setAttribute('src', '/assets/images/top/hero-area-sp.png')
    imgElement.setAttribute('alt', 'hero sp image');
    imgElement.setAttribute('loading', 'lazy');
    imgElement.setAttribute('class', 'heroVideo__spImageForCanNotPlay')
    wrapperElement.appendChild(imgElement);

    // SPのYoutubeのiframeを削除する
    const spElement = document.getElementById("youtubeForSp")
    spElement.remove();
  }
}

if(["/", "/en/", "/ja/"].includes(currentPagePath)) {
  new Main();
}
