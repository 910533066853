export const currentPagePath = window.location.pathname;
const breakpoint = 768;
export const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);

/*** delay ***/

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
export const disableTransition = (target) => {
  target.classList.add('-disable-transition');
  delay(300).then(() => { target.classList.remove('-disable-transition'); });
};
