import * as easing from '../utils/easing.esm.mjs';
import { currentPagePath, delay, disableTransition, mediaQuery } from '../common.js';

/**
 *  news
 */
if (currentPagePath.match('\\/news\\/$') || currentPagePath.match('^\\/news\\/\\d{4}\\/$')) {

/*** dropdown-menu ***/
/***
 * ドロップダウンメニューで掲載年の絞り込み
 *  絞り込み時にpushstateにより履歴操作し擬似的に静的URLとして表示
 * 中身はAjaxにより下層ページから参照して出力、メタデータも置き換えることで擬似的にSPAのようにみせてます。
 * 下層も構成がまったく同じなのでリダイレクトはしてません。
 * ↓
 * 一覧ページは PHP 側で都度動的生成する形にしたため JS による SPA 的な処理を削除。
 ***/

const dropdownOpenTrigger = document.querySelector('[aria-controls=newsSelect]');
const dropdownOpenTarget = document.getElementById('newsSelect');
const dropdownSelectTrigger = dropdownOpenTarget.querySelectorAll(':scope > li a');
const hiddenDropdown = document.querySelector('.dropdownSelect__hidden');
const newsListTrigger = document.querySelector('[aria-controls=newsList]');
let newsList = document.getElementById('newsList');
let newsListParent = newsList.parentNode;
let requestYearDocument;

// const metadataNeutral = {
//   title: document.querySelector('title').textContent,
//   description: document.querySelector('meta[name="description"]').getAttribute('content'),
//   keywords:  document.querySelector('meta[name="keywords"]').getAttribute('content'),
//   twtTitle: document.querySelector('meta[name="twitter:title"]').getAttribute('content'),
//   twtDescription: document.querySelector('meta[name="twitter:description"]').getAttribute('content'),
//   twtImage: document.querySelector('meta[name="twitter:image"]').getAttribute('content'),
//   ogTitle: document.querySelector('meta[property="og:title"]').getAttribute('content'),
//   ogDescription: document.querySelector('meta[property="og:description"]').getAttribute('content'),
//   ogUrl: document.querySelector('meta[property="og:url"]').getAttribute('content'),
//   ogImage: document.querySelector('meta[property="og:image"]').getAttribute('content'),
// };
// let metadataNew = metadataNeutral;

// // メタデータオブジェクトのアップデート
// const updateMetadata = (newDocument, modal = true) => {
//   if (!modal) {
//     metadataNew = metadataNeutral;
//   } else {
//     metadataNew = {
//       title: newDocument.querySelector('title').textContent,
//       description: newDocument.querySelector('meta[name="description"]').getAttribute('content'),
//       keywords:  newDocument.querySelector('meta[name="keywords"]').getAttribute('content'),
//       twtTitle: newDocument.querySelector('meta[name="twitter:title"]').getAttribute('content'),
//       twtDescription: newDocument.querySelector('meta[name="twitter:description"]').getAttribute('content'),
//       twtImage: newDocument.querySelector('meta[name="twitter:image"]').getAttribute('content'),
//       ogTitle: newDocument.querySelector('meta[property="og:title"]').getAttribute('content'),
//       ogDescription: newDocument.querySelector('meta[property="og:description"]').getAttribute('content'),
//       ogUrl: newDocument.querySelector('meta[property="og:url"]').getAttribute('content'),
//       ogImage: newDocument.querySelector('meta[property="og:image"]').getAttribute('content'),
//     };
//   }
// };

// // メタデータの差し替え処理
// const replaceeMetaTags = () => {
//   document.querySelector('title').textContent = metadataNew.title;
//   document.querySelector('meta[name="description"]').setAttribute('content', metadataNew.description);
//   document.querySelector('meta[name="keywords"]').setAttribute('content', metadataNew.keywords);
//   document.querySelector('meta[name="twitter:title"]').setAttribute('content', metadataNew.twtTitle);
//   document.querySelector('meta[name="twitter:description"]').setAttribute('content', metadataNew.description);
//   document.querySelector('meta[name="twitter:image"]').setAttribute('content', metadataNew.twtImage);
//   document.querySelector('meta[property="og:title"]').setAttribute('content', metadataNew.ogTitle);
//   document.querySelector('meta[property="og:description"]').setAttribute('content', metadataNew.ogDescription);
//   document.querySelector('meta[property="og:url"]').setAttribute('content', metadataNew.ogUrl);
//   document.querySelector('meta[property="og:image"]').setAttribute('content', metadataNew.ogImage);
// };


// ドロップダウンメニューの初期ラベルを設定（即時）
(() => {
  const selectedValue = hiddenDropdown.value;
  newsListTrigger.querySelector('.dropdownSelect__label').textContent = (selectedValue == '') ? 'ALL': selectedValue;
})();

// ドロップダウンメニューを開くハンドラ
const handleToggleDropdownMenu = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const isHidden = dropdownOpenTarget.getAttribute('aria-hidden') === 'true';
  dropdownOpenTarget.setAttribute('aria-hidden', !isHidden);
  dropdownOpenTrigger.setAttribute('aria-expanded', !!isHidden);
  if (!isHidden) {
    dropdownOpenTarget.style.maxHeight = '0';
  } else {
    dropdownOpenTarget.style.maxHeight = `${dropdownOpenTarget.scrollHeight}px`;
  }
};

// ドロップダウンメニューで掲載年の絞り込みを切り替えるハンドラ
const handleSelectDropdownMenu = (event) => {
  // event.preventDefault();
  // event.stopPropagation();
  const selectedValue = event.currentTarget.textContent.trim();
  location.href = location.pathname + '?year=' + selectedValue;

  // newsListTrigger.querySelector('.dropdownSelect__label').textContent = selectedValue;
  // hiddenDropdown.value = (selectedValue == 'ALL') ? '' : selectedValue;
  // hiddenDropdown.dispatchEvent(new Event('change'));
  // dropdownOpenTarget.setAttribute('aria-hidden', true);
  // dropdownOpenTrigger.setAttribute('aria-expanded', false);
  // dropdownOpenTarget.style.maxHeight = '0';
};

// ドロップダウンメニューを閉じるハンドラ
const handleCloseDropdownMenu = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (!newsListTrigger.contains(event.currentTarget)) {
    dropdownOpenTarget.setAttribute('aria-hidden', true);
    dropdownOpenTrigger.setAttribute('aria-expanded', false);
    dropdownOpenTarget.style.maxHeight = '0';
  }
};

// ドロップダウンメニューが開いている時のリサイズ時の高さ再調整を行うハンドラ
const handleNewsSelectChangeHeight = () => {
  const isHidden = dropdownOpenTarget.getAttribute('aria-hidden') === 'true';
  if (!isHidden) dropdownOpenTarget.style.maxHeight = `${dropdownOpenTarget.scrollHeight}px`;
};

// 掲載年の絞り込みデータを取得し入れ替える処理
// const requestNewsList = (xhr, url) => {
//   console.log('reqNewsList');
//   const responseData = xhr.responseText;
//   const parser = new DOMParser();
//   requestYearDocument = parser.parseFromString(responseData, 'text/html');
//   const requestNewsList = requestYearDocument.getElementById('newsList');
//   const requestNewsListParent = requestNewsList.parentNode;
//   if (!requestNewsListParent) return false;
//   const requestNewsListParentHTML = requestNewsListParent.innerHTML;
//   newsListParent.innerHTML = requestNewsListParentHTML;
//   newsList = newsListParent.children[0];
//   newsListParent = newsList.parentNode;
//   newsList.setAttribute('aria-hidden', true);
//   updateMetadata(requestYearDocument);
//   history.pushState({}, '', url);
//   const popStateEvent = new PopStateEvent('popstate');
//   window.dispatchEvent(popStateEvent);
//   newsList.setAttribute('aria-hidden', false);
// };

// 掲載年の絞り込みデータをリクエストする処理
const newsChangeYear = (event) => {
  location.href = location.pathname + '?year=' + event.target.value;
};

const resizeEventTypes = ['resize', 'orientationchange', 'load'];
resizeEventTypes.forEach(eventType => {
  window.addEventListener(eventType, handleNewsSelectChangeHeight);
});
dropdownOpenTrigger.addEventListener('click', handleToggleDropdownMenu);
dropdownSelectTrigger.forEach(item => item.addEventListener('click', handleSelectDropdownMenu));
//document.addEventListener('click', handleCloseDropdownMenu);
//window.addEventListener('popstate', replaceeMetaTags);
hiddenDropdown.addEventListener('change', newsChangeYear);

}// matching currentPagePath
