import * as easing from '../utils/easing.esm.mjs';
import { currentPagePath, delay, disableTransition, mediaQuery } from '../common.js';


/**
 * members-details
 */

/*** redirect ***/
/***
 * htacssessのリダイレクトの方が読み込み遅延も無くスマートなので
 * 設置可能になるまでの代替え処理としてJSで対応
 * 一応、IIFEで対応してますがhtacssessなどが最善
 ***/

const redirectTargetUrl = currentPagePath.match('^(\\/members\\/)profiles\\/([^\\/]*)\\/$');

if (redirectTargetUrl) {

// 下層URLをURLクエリパラメータ持ちの動的URLに変換しつつリダイレクト
(() => {
  const redirectUrlStatic = redirectTargetUrl[1];
  const param = redirectTargetUrl[2];
  const redirectUrl = `${redirectUrlStatic}index.html?profiles=${param}`;
  window.location.href = redirectUrl;
})();

}// matching currentPagePath

/**
 * members-all
 */

/*** modal ***/
/***
 * モーダル開閉時にpushstateにより履歴操作し擬似的に静的URLとして表示
 * 中身はAjaxにより下層ページから参照して出力、メタデータも置き換えることで擬似的にSPAのようにみせてます。
 * また、実際の下層ページからはリダイレクトされるように設定してます。
 * リダイレクト時には静的な下層URLを動的URL化することでクエリをトリガーにしてモーダルを開いています。
 ***/

if (currentPagePath.match('^\\/members\\/$') || currentPagePath.match('^\\/members\\/index\\.html$')) {

const modalContent = document.getElementById('membersModal');
const modalContentSpecific = document.querySelector('.membersModal__specific');
let modalDocument;
let scrollTop = modalContent.scrollTop;
let clientHeight = modalContent.clientHeight;
let scrollHeight = modalContent.scrollHeight;

const metadataNeutral = {
  title: document.querySelector('title').textContent,
  description: document.querySelector('meta[name="description"]').getAttribute('content'),
  keywords:  document.querySelector('meta[name="keywords"]').getAttribute('content'),
  twtTitle: document.querySelector('meta[name="twitter:title"]').getAttribute('content'),
  twtDescription: document.querySelector('meta[name="twitter:description"]').getAttribute('content'),
  twtImage: document.querySelector('meta[name="twitter:image"]').getAttribute('content'),
  ogTitle: document.querySelector('meta[property="og:title"]').getAttribute('content'),
  ogDescription: document.querySelector('meta[property="og:description"]').getAttribute('content'),
  ogUrl: document.querySelector('meta[property="og:url"]').getAttribute('content'),
  ogImage: document.querySelector('meta[property="og:image"]').getAttribute('content'),
};
let metadataNew = metadataNeutral;

// メタデータオブジェクトのアップデート
const updateMetadata = (newDocument, modal = true) => {
  if (!modal) {
    metadataNew = metadataNeutral;
  } else {
    metadataNew = {
      title: newDocument.querySelector('title').textContent,
      description: newDocument.querySelector('meta[name="description"]').getAttribute('content'),
      keywords:  newDocument.querySelector('meta[name="keywords"]').getAttribute('content'),
      twtTitle: newDocument.querySelector('meta[name="twitter:title"]').getAttribute('content'),
      twtDescription: newDocument.querySelector('meta[name="twitter:description"]').getAttribute('content'),
      twtImage: newDocument.querySelector('meta[name="twitter:image"]').getAttribute('content'),
      ogTitle: newDocument.querySelector('meta[property="og:title"]').getAttribute('content'),
      ogDescription: newDocument.querySelector('meta[property="og:description"]').getAttribute('content'),
      ogUrl: newDocument.querySelector('meta[property="og:url"]').getAttribute('content'),
      ogImage: newDocument.querySelector('meta[property="og:image"]').getAttribute('content'),
    };
  }
};

// メタデータの差し替え処理
const replaceeMetaTags = () => {
  document.querySelector('title').textContent = metadataNew.title;
  document.querySelector('meta[name="description"]').setAttribute('content', metadataNew.description);
  document.querySelector('meta[name="keywords"]').setAttribute('content', metadataNew.keywords);
  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metadataNew.twtTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metadataNew.description);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metadataNew.twtImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', metadataNew.ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metadataNew.ogDescription);
  document.querySelector('meta[property="og:url"]').setAttribute('content', metadataNew.ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metadataNew.ogImage);
};

// スクロールできなくする処理
const disableBackgroundScroll = () => {
  document.body.style.overflow = 'hidden';
};

// スクロール可能に戻す処理
const enableBackgroundScroll = () => {
document.body.style.overflow = '';
};

// モーダルを開く処理
const openModal = (id) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', '/members/profiles/' + id + '/', true);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const responseData = xhr.responseText;
        const parser = new DOMParser();
        modalDocument = parser.parseFromString(responseData, 'text/html');
        const specificElement = modalDocument.querySelector('.membersModal__specific');
        if (!specificElement) return false;
        const specificHTML = specificElement.innerHTML;
        modalContentSpecific.innerHTML = specificHTML;
        modalContent.setAttribute('aria-hidden', false);
        disableBackgroundScroll();

        updateMetadata(modalDocument);
        history.pushState({}, '', '/members/profiles/' + id + '/');
        const popStateEvent = new PopStateEvent('popstate');
        window.dispatchEvent(popStateEvent);
      }
    }
  };
  xhr.send();
};

// URLクエリパラメータを取得すると自動でモーダルを開くハンドラ
const handleModalLinkParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryParamValue = urlParams.get('profiles');
  if (queryParamValue) {
    disableTransition(modalContent);
    openModal(queryParamValue);
  }
};

// モーダルを開くハンドラ
const handleOpenModal = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const id = event.currentTarget.dataset.membersModal;
  openModal(id);
};

// モーダルを閉じるハンドラ
const handleCloseModal = (event) => {
  event.preventDefault();
  event.stopPropagation();
  modalContent.setAttribute('aria-hidden', true);
  enableBackgroundScroll();

  updateMetadata('', false);
  history.pushState({}, '', '/members/');
  const popStateEvent = new PopStateEvent('popstate');
  window.dispatchEvent(popStateEvent);
};

// モーダル内スクロール最後尾までスクロールしきったタイミングで閉じるボタンを表示するハンドラ（モバイル時）
const handleVisibleCloseBtnForMobileScroll = () => {
  scrollTop = modalContent.scrollTop;
  clientHeight = modalContent.clientHeight;
  scrollHeight = modalContent.scrollHeight;

  if (scrollTop + clientHeight >= scrollHeight - (80 + 48 / 2)) {
    document.querySelector('[data-close="membersModal"][aria-hidden]').setAttribute('aria-hidden', true);
  } else {
    document.querySelector('[data-close="membersModal"][aria-hidden]').setAttribute('aria-hidden', false);
  }
};

// デバイス（リサイズ）により閉じるボタンを表示非表示するハンドラ
const handleVisibleCloseBtnByDevice = () => {
  if (mediaQuery.matches) {
    document.querySelector('[data-close="membersModal"][aria-hidden]').setAttribute('aria-hidden', true);
  } else {
    if (scrollTop + clientHeight >= scrollHeight - (80 + 48 / 2)) {
      document.querySelector('[data-close="membersModal"][aria-hidden]').setAttribute('aria-hidden', true);
    } else {
      document.querySelector('[data-close="membersModal"][aria-hidden]').setAttribute('aria-hidden', false);
    }
  }
};


const resizeEventTypes = ['resize', 'orientationchange', 'load'];
resizeEventTypes.forEach(eventType => {
  window.addEventListener(eventType, handleVisibleCloseBtnByDevice);
});
window.addEventListener('popstate', replaceeMetaTags);
window.addEventListener('DOMContentLoaded', handleModalLinkParam);
document.querySelectorAll('[data-members-modal]').forEach(element => element.addEventListener('click', handleOpenModal));
document.querySelectorAll('[data-close="membersModal"]').forEach(element => element.addEventListener('click', handleCloseModal));
modalContent.addEventListener('scroll', handleVisibleCloseBtnForMobileScroll);

}// matching currentPagePath
