/**
 * トップページ「プロジェクト事例」背景のアニメーションをThree.jsを使って実装
 * @related: frontend/src/page/index.html
 */

// Three.jsライブラリをインポート
import * as THREE from 'three';
import bar1_1 from '../../images/top/animation/company-logo-bar-1-1.jpg';
import bar1_2 from '../../images/top/animation/company-logo-bar-1-2.jpg';
import bar1_3 from '../../images/top/animation/company-logo-bar-1-3.jpg';
import bar1_4 from '../../images/top/animation/company-logo-bar-1-4.jpg';
import bar1_5 from '../../images/top/animation/company-logo-bar-1-5.jpg';
import bar2 from '../../images/top/animation/company-logo-bar-2.jpg';
import bar4 from '../../images/top/animation/company-logo-bar-4.jpg';
import {currentPagePath} from "@/script/common";

// 定数の設定
const SCENE_SIZE = 90;
const FLOAT_SPEED = 0.05;
const ROTATION_SPEED = 0.005;
const ORBIT_RADIUS = 150;
let angle = 0;
let bars = [];

// DOM要素の取得
const containerElement = document.getElementById('animationHomeProjectExampleContainer');
const element = document.getElementById('animationHomeProjectExample');

if(["/", "/en/", "/ja/"].includes(currentPagePath)) {
  const width = document.body.clientWidth;
  const height = containerElement.getBoundingClientRect().height;

  // シーンの初期化
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 5000);
  camera.position.z = 10000;
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.setSize(width, height);
  element.appendChild(renderer.domElement);

  // バーのテクスチャリスト
  const barTextureList = [bar1_1, bar1_2, bar1_3, bar1_4, bar1_5, bar2, bar2, bar2, bar2, bar2, bar2, bar2, bar2, bar4, bar4, bar4, bar4];

  // テクスチャローダーの初期化
  const textureLoader = new THREE.TextureLoader();

  // テクスチャを非同期で読み込む関数
  async function loadTextureAsync(url) {
    return new Promise((resolve, reject) => {
      textureLoader.load(url, resolve, undefined, reject);
    });
  }

  // バーを作成し、シーンに追加する関数
  async function createBars() {
    const geometry = new THREE.BoxGeometry(3, 16, 0.1);
    for (const texturePath of barTextureList) {
      try {
        const texture = await loadTextureAsync(texturePath);
        texture.encoding = THREE.sRGBEncoding;
        const material = new THREE.MeshBasicMaterial({ map: texture });
        const bar = new THREE.Mesh(geometry, material);
        setBarProperties(bar);
        scene.add(bar);
        bars.push(bar);
      } catch (error) {
        console.error('テクスチャの読み込みに失敗しました:', error);
      }
    }
  }

  // バーのプロパティを設定する関数
  function setBarProperties(bar) {
    bar.position.set(
      Math.random() * SCENE_SIZE - SCENE_SIZE / 2,
      Math.random() * SCENE_SIZE - SCENE_SIZE / 2,
      Math.random() * SCENE_SIZE - SCENE_SIZE / 2
    );
    bar.userData.velocity = new THREE.Vector3(
      (Math.random() - 0.5) * FLOAT_SPEED,
      (Math.random() - 0.5) * FLOAT_SPEED,
      (Math.random() - 0.5) * FLOAT_SPEED
    );
    bar.userData.rotationSpeed = new THREE.Vector3(
      (Math.random() - 0.5) * ROTATION_SPEED,
      (Math.random() - 0.5) * ROTATION_SPEED,
      (Math.random() - 0.5) * ROTATION_SPEED
    );
  }

  // アニメーションループ
  function animate() {
    requestAnimationFrame(animate);

    // カメラの軌道位置を更新
    angle += ROTATION_SPEED;
    camera.position.x = ORBIT_RADIUS * Math.sin(angle);
    camera.position.y = 0;
    camera.position.z = ORBIT_RADIUS * Math.cos(angle);
    camera.lookAt(scene.position);

    // バーの回転
    bars.forEach(bar => {
      bar.rotation.x += bar.userData.rotationSpeed.x;
      bar.rotation.y += bar.userData.rotationSpeed.y;
      bar.rotation.z += bar.userData.rotationSpeed.z;
    });

    renderer.render(scene, camera);
  }

  // ウィンドウリサイズイベントのハンドラ
  function onWindowResize(event) {
    // コンテナ要素の現在の幅と高さを取得
    const newWidth = document.body.clientWidth;
    const newHeight = containerElement.getBoundingClientRect().height;

    // カメラのアスペクト比を更新
    camera.aspect = newWidth / newHeight;
    camera.updateProjectionMatrix();

    // レンダラーのサイズを更新
    renderer.setSize(newWidth, newHeight);
  }


  window.addEventListener('resize', onWindowResize,false);

  // 初期化とアニメーションの開始
  createBars().then(() => {
    onWindowResize();  // 初期設定でサイズを設定
    animate();
  });

}

